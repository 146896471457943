import React from "react"
import { graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Paragraph from "../components/Paragraph"
import Seo from "../components/seo"
import Title from "../components/Title"
import rehypeReact from "rehype-react"

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {"p": Paragraph},
}).Compiler

const IndexPage = ({data}) => {
  const {markdownRemark: markdown} = data
  const image = getImage(markdown.frontmatter.image)
  
  return <Layout>
    <Seo title={markdown.frontmatter.title} />
    
    <Title>
      {markdown.frontmatter.title}
    </Title>

    {renderAst(markdown?.htmlAst)}

    <GatsbyImage image={image} alt="Langeland Trio" />
    
  </Layout>
}

export const query = graphql`
query {
  markdownRemark(fields: { slug: { eq: "/" } }) {
    htmlAst
    frontmatter {
      title
      image {
        childImageSharp {
          gatsbyImageData(
            width: 1000
            quality: 90
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  }
}
`

export default IndexPage
